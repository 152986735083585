import { createContext, useState } from "react";

const LANG_UKRAINIAN = "uk";
const LANG_ENGLISH = "en";

const LANG_LABELS = { };
Object.defineProperty(LANG_LABELS, LANG_UKRAINIAN, { value: 'ua', writable: false, enumerable: true });
Object.defineProperty(LANG_LABELS, LANG_ENGLISH, { value: 'en', writable: false, enumerable: true });

const getInitialLanguage = () => {
  const requested = window.location.hash.replace("#", "");
  let customer = new RegExp(`^${LANG_UKRAINIAN}`).test(navigator.language) ? LANG_UKRAINIAN : LANG_ENGLISH;

  if (requested.length) {
    if (Object.values(LANG_LABELS).includes(requested)) {
      for (const id in LANG_LABELS) {
        if (LANG_LABELS[id] === requested) {
          customer = id;
        }
      }
    }
  }

  return customer;
};

const LanguageContext = createContext({
  language: null,
  isUkrainianUI: () => { },
  getNextLanguage: () => { },
  toggleLanguage: () => { }
})

const LanguageProvider = ({ children }) => {
  const [language, setLanguageState] = useState(getInitialLanguage());
  const isUkrainianUI = () => language === LANG_UKRAINIAN;
  const getNextLanguage = () => isUkrainianUI() ? LANG_LABELS[LANG_ENGLISH] : LANG_LABELS[LANG_UKRAINIAN];
  const toggleLanguage = () => {
    setLanguageState(isUkrainianUI() ? LANG_ENGLISH : LANG_UKRAINIAN)
    window.history.pushState({}, "", isUkrainianUI() ? "#en" : "#ua")
  }

  return (
    <LanguageContext.Provider value={{ language, isUkrainianUI, getNextLanguage, toggleLanguage }}>
      { children }
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider };