// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/hero-img.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero-section {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: top center;\n  display: flex;\n  flex-direction: column;\n  background-size: calc(100% + 330px); }\n  @media screen and (min-width: 768px) {\n    .hero-section {\n      background-size: cover;\n      background-position: center; } }\n\n.hero-content {\n  margin-bottom: auto;\n  margin-top: auto;\n  padding-top: 13rem; }\n  @media screen and (min-width: 1024px) {\n    .hero-content {\n      padding-top: 0;\n      transform: translateY(-100px); } }\n\n.autor {\n  display: block;\n  color: #383838;\n  font-size: 16px;\n  margin-bottom: .3rem; }\n", "",{"version":3,"sources":["webpack://./src/scss/hero-section.scss"],"names":[],"mappings":"AAAA;EACE,yDAA4C;EAC5C,4BAA4B;EAC5B,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,mCAAmC,EAAA;EAEnC;IARF;MASI,sBAAsB;MACtB,2BAA2B,EAAA,EAE9B;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB,EAAA;EAElB;IALF;MAMI,cAAc;MACd,6BAA6B,EAAA,EAEhC;;AAED;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,oBAAoB,EAAA","sourcesContent":[".hero-section {\n  background-image: url(\"../img/hero-img.jpg\");\n  background-repeat: no-repeat;\n  background-position: top center;\n  display: flex;\n  flex-direction: column;\n  background-size: calc(100% + 330px);\n\n  @media screen and (min-width: 768px) {\n    background-size: cover;\n    background-position: center;\n  }\n}\n\n.hero-content {\n  margin-bottom: auto;\n  margin-top: auto;\n  padding-top: 13rem;\n\n  @media screen and (min-width: 1024px) {\n    padding-top: 0;\n    transform: translateY(-100px);\n  }\n}\n\n.autor {\n  display: block;\n  color: #383838;\n  font-size: 16px;\n  margin-bottom: .3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
