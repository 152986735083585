import '../scss/components/assets/header.scss';
import LanguageSwitcher from "./LanguageSwitcher";
import UrlButton from "./UrlButton";

function Header () {
  return <header>
    <div className="container">
      <div className="toolbar-panel">
        <div className="toolbar">
          <a href="https://qua.community/lgbtq-in-defense-of-ukraine/" target="_blank">
            <img src="/qua-logo.svg" alt=""/>
          </a>
        </div>
        <div className="toolbar align-right">
          <LanguageSwitcher />
          <div>
            <UrlButton />
          </div>
        </div>
      </div>
    </div>
  </header>
}

export default Header;
