import {createContext, useState} from "react";

const PageSliderContext = createContext({
  slider: undefined,
  setSliderInstance: (slider) => { }
})

const PageSliderProvider = ({ children }) => {
  const [slider, setSliderState] = useState(undefined);
  const setSliderInstance = (slider) => {
    setSliderState(slider);
  }

  return (
    <PageSliderContext.Provider value={{ slider, setSliderInstance }}>
      { children }
    </PageSliderContext.Provider>
  )
}

export { PageSliderContext , PageSliderProvider }