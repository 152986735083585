const ScrollToTop = (props) => {

  return (
    <div className="btn btn-scroll-top" onClick={props.ScrollTop}>
      <img src="/icons/arrow.-outline.svg" alt="Scroll to top" />
    </div>
  );
}

export default ScrollToTop;
