import UrlButton from "../components/UrlButton";

function Intro(props) {
  return (
    <>
      <div className="shape skew"></div>
      <div className="container container-intro">
        <UrlButton className="btn-donate" />
        <h3 className="hero-slide__title">LGBTIQ+ {props.languageUA ? "на захисті" : "in defense"}<br />{props.languageUA ? "України" : "of Ukraine"}</h3>
        <figure className="quote">
          <blockquote className="quote__text">
            <p>
              {props.languageUA ?
                <> “Несправедливість будь-де є загрозою справедливості всюди. Ми потрапили в невідворотну мережу<br/>
                  взаємності, пов’язаної в єдиний одяг долі. Все, що впливає на одного безпосередньо, впливає на всіх опосередковано.<br/>
                  Ми ніколи більше не зможемо дозволити собі жити з вузькою, провінційною ідеєю «зовнішнього агітатора».”
                </> :
                <> “Injustice anywhere is a threat to justice everywhere. We are caught in an inescapable network<br/>
                  of mutuality, tied in a single garment of destiny. Whatever affects one directly, affects all
                  indirectly.<br/>
                  Never again can we afford to live with the narrow, provincial 'outside agitator' idea.”
                </>
              }
            </p>
          </blockquote>
          <figcaption className="quote__caption">Dr. Martin Luther King, 1963.</figcaption>
        </figure>
        <div className="content content_two-col content_wide-right-padding">
          {props.languageUA ?
            <>
              <p className="strong">QUA - ЛГБТК українці в Америці з радістю презентують фотовиставку
                з портретами українських ЛГБТ героїв війни.</p>
              <p>Ці фотографії створив український фотограф Алім Якубов. Виставка супроводжується особистими історіями українських героїв, зібраними та перекладеними Дафною Рачок. Вона представлена як розмова з надзвичайними ЛГБТК людьми, які після жорстокого вторгнення Росії в Україну в 24 лютого стали захисниками своєї країни і лише за одну ніч почали нове життя як солдати або волонтери, приєднавшись або започаткувавши гуманітарний проект для допомоги іншим людям. Всі вони ризикують життям задля демократичної та незалежної України та вирішують залишитися та боротися проти Російської окупації. Наша ідея – показати єдність і людяність українського народу, включно ЛГБТК-спільноту. ЛГБТК-українці завжди були частиною прогресу України, і ця боротьба за виживання нації нічим не відрізняється. Ми хочемо підкреслити стійкість ЛГБТ українських військових та гуманітарних волонтерів.</p>
              <p>Завдяки портретам та інтерв’ю з цими героями ми представимо цей перетин українського живого досвіду зі Сполученими Штатами у глибоко особистий спосіб, будуючи співчуття та мости між Американською та Українською громадами.</p>
            </> :
            <>
              <p className="strong">QUA - LGBTQ Ukrainians in America are thrilled to present a photo exhibition
                featuring portraits of Ukrainian LGBTQ war heroes.</p>
              <p>These photos were created by the Ukrainian photographer Alim Yakubov. The exhibition is accompanied
                with the personal stories of Ukrainian heroes collected and translated by Dafna Rachok. It is framed as
                a conversation with extraordinary LGBTQ people who after Russia’s brutal invasion of Ukraine on February
                24 became defenders of their country, and just in one night started a new life as soldiers or
                volunteers, joining the army or starting a humanitarian project to help other people. All of them are
                risking their lives for a democratic and independent Ukraine and chose to stay and fight against the
                Russian occupation. Our idea is to show the unity and humanity of the Ukrainian people, including the
                LGBTQ community. LGBTQ Ukrainians have always been a part of Ukraine’s progress, and this fight for the
                survival of the nation is no different. We wish to highlight the resilience of LGBTQ Ukrainian military
                and humanitarian volunteers.</p>
              <p>By portraits and interviews with these heroes we will bring this intersection of Ukrainian
                lived experience to the United States in a deeply personal way, building empathy and bridges between
                American and Ukrainian communities.</p>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Intro;
