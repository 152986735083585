import Modal from "react-modal";
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const PREFIX_PICTURE_LOW = '/pictures-low';

const Story = ({ hero, isOpen, onClose }) => {
  const nextArrow = (onClick, isActive) => {
    if (!isActive) {
      return null;
    }

    return (
      <button className="btn btn-navigation btn-navigation_next" onClick={onClick}>
        <img src="/icons/arrow.svg" alt=""/>
      </button>
    );
  };

  const prevArrow = (onClick, isActive) => {
    if (!isActive) {
      return null;
    }

    return (
      <button className="btn btn-navigation btn-navigation_prev" onClick={onClick}>
        <img src="/icons/arrow.svg" alt=""/>
      </button>
    );
  };

  const onWheelAndTouchEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  return (
    <div onWheelCapture={onWheelAndTouchEvents} onTouchMove={onWheelAndTouchEvents}>
      <Modal isOpen={isOpen} onRequestClose={onClose} className="hero-modal" overlayClassName="hero-modal__overlay">
        <div className="container">
          <div className="hero-modal__header">
            <div>
              <div className="btn-back">
                <i className="icon icon_small icon-arrow-left"></i>
                <a href="#go-back" className="hero-modal__close" onClick={onClose}>Go Back</a>
              </div>
            </div>
          </div>
          <div className="hero-modal__story">
            <h3 className="hero-story__title">{hero.name}</h3>
            <span className="hero-story__subtitle">{hero.position}</span>
            <div className="hero-story__content">
              <Scrollbars autoHeightMax={'100%'} autoHide={false} hideTracksWhenNotNeeded={false}>
                {
                  hero.content.map((text, idx) => (<p key={idx}>{text}</p>))
                }
              </Scrollbars>
            </div>
          </div>
          <div className="hero-modal__pictures">
            <div>
              <Carousel showThumbs={false} showStatus={false} showIndicators={false} renderArrowPrev={prevArrow} renderArrowNext={nextArrow}>
                {
                  hero.images.map((image, idx) => (
                    <div key={idx}>
                      <div className="frame">
                        <img src={`${PREFIX_PICTURE_LOW}${image}`.replace(/\/+/g,"/")}/>
                      </div>
                    </div>
                  ))
                }
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Story;