import './App.scss';
import { HeroesProvider } from "./context/HeroesContext";
import { LanguageProvider } from "./context/LanguageContext";
import { PageSliderProvider } from "./context/PageSliderContext";
import Heroes from "./components/Heroes";

function App() {

  return (
    <div className="App">
      <LanguageProvider>
        <PageSliderProvider>
          <HeroesProvider>
            <Heroes/>
          </HeroesProvider>
        </PageSliderProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;