function Landing(props) {

  return (
    <section className="hero-content">
      <div className="container">
        <h1>LGBTIQ+ <br></br>{props.languageUA ? "на захисті" : "in defense"}<br></br>{props.languageUA ? "України" : "of Ukraine"}</h1>
        <span className="autor">{props.languageUA ? "Фото Аліма Якубова" : "Photography by Alim Yakubov"}</span>
        <span className="autor">{props.languageUA ? "Історія Дафни Рачок" : "Story by Dafna Rachok"}</span>
      </div>
    </section>
  )
}

export default Landing;
