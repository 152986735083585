import HeroesEN from "../data/heroes.en.json";
import HeroesUA from "../data/heroes.ua.json";
import Pictures from "../data/pictures.json";
import { createContext, useState, useContext } from "react";
import {LanguageContext} from "./LanguageContext";

const normalizeHeroes = (source) => {
  return source.map((hero) => {
    return Object.assign(
      hero,
      Object.assign({ preview: "", images: [] }, Pictures.find(item => item.id === hero.id) || { })
    );
  });

}

const HeroesContext = createContext({
  hero: null,
  count: 0,
  getHero: (idx) => { },
});

const HeroesProvider = ({ children }) => {
  const heroes = {
    uk: normalizeHeroes(HeroesUA),
    en: normalizeHeroes(HeroesEN),
  };

  const { language } = useContext(LanguageContext);
  const count = heroes[language].length;
  const [hero, setHero] = useState(null);
  const getHero = (idx) => {
    if (idx > count) {
      return null;
    }

    return heroes[language][idx];
  };

  return (
    <HeroesContext.Provider value={{ hero, count, getHero }}>
      { children }
    </HeroesContext.Provider>
  )
}

export { HeroesProvider , HeroesContext }
