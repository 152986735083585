import "../scss/sections/_participants.scss"
import ScrollToTop from "../components/ScrollToTop";
import UrlButton from "../components/UrlButton";

const Participants = (props) => {
  return (
    <div>
      <div className="container container-participants">
        <UrlButton className="btn-donate" />
        <div className="hero-slide__info">
          <p className="hero-slide__caption">LGBTIQ+ {props.languageUA ? "на захисті України" : "in defense of Ukraine"}</p>
          <h3 className="hero-slide__title">{props.languageUA ? "Біографія учасників" : "Participant Bios"}</h3>
          <div className="row row-black-hole">
            <div className="col col_one-half">
              <div className="participant">
                <h4 className="participant__title">{props.languageUA ? "Алім Якубов" : "Alim Yakubov"}</h4>
                <p className="participant__content">
                  {props.languageUA ? "Алім Якубов — український кримський фотограф. Алім – Киримлі, повернувся на прабатьківщину з Узбекистану в 2008 році, як і більшість кримських татар. До 2014 року жив у Криму, а після окупації був змушений переїхати до Києва, де й проживає зараз. Алім займається фотографією з 2012 року, працює зі стоковою та портретною фотографією. До повномасштабного вторгнення 24 лютого 2022 року він багато подорожував і особливо захоплювався вуличною фотографією."
                  : "Alim Yakubov is a Ukrainian Crimean photographer. Alim is qirimli, he returned to the ancestor homeland from Uzbekistan in 2008, like most Crimean Tatars. He lived in Crimea until 2014 and after the occupation was forced to move to Kyiv where he lives now. Alim has been engaged in photography since 2012, works with stock photography and portrait photography. Before the full-scale invasion on February 24, 2022, he traveled a lot and was particularly into street photography."}
                </p>
              </div>
            </div>
            <div className="col col_one-half">
              <div className="participant">
                <h4 className="participant__title">{props.languageUA ? "Дафна Рачок" : "Dafna Rachok"}</h4>
                <p className="participant__content">
                  {props.languageUA ? "Дафна Рачок – антрополог, яка народилася в Україні та отримала освіту в США. З 24 лютого вона є постійним волонтером, допомагаючи українським військовим. Жила в Україні, Угорщині, Канаді, США."
                    : "Dafna Rachok is a Ukraine-born and US-educated anthropologist. Since February 24th, she has been a full-time volunteer helping the Ukrainian war effort. She has lived in Ukraine, Hungary, Canada, and the USA."
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="row mob-row-pad">
            <div className="toolbar">
              <a href="https://qua.community/lgbtq-in-defense-of-ukraine/" target="_blank">
                <img src="/qua-logo.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ScrollToTop ScrollTop={props.ScrollTop}/>
      </div>
    </div>
  )
}

export default Participants;
