import '../scss/hero-section.scss';
import '../scss/components/assets/slider.scss';
import '../scss/components/assets/fullpage-styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactFullpage from "@fullpage/react-fullpage";
import { useContext } from "react";
import { HeroesContext } from "../context/HeroesContext";
import { LanguageContext } from "../context/LanguageContext";
import Header from "./Header";
import Landing from "../sections/Landing";
import Intro from "../sections/Intro";
import Hero from "../sections/Hero";
import Participants from "../sections/Participants";

const Heroes = () => {
  const { count } = useContext(HeroesContext);
  const { isUkrainianUI } = useContext(LanguageContext);

  return (
    <ReactFullpage
      navigation
      scrollingSpeed = {800}
      render={({fullpageApi}) => {
        return (
          <div className="wrapper">
            <div className="section">
              <div className="main-container hero-section">
                <Header/>
                <Landing languageUA={isUkrainianUI()}/>
                <div className="btn btn-scroll-bottom" onClick={() => fullpageApi.moveSectionDown()}>
                  <img src="/icons/arrow.-outline.svg" alt="Scroll to top" />
                </div>
              </div>
            </div>
            <div className="hero-slide section">
              <Intro languageUA={isUkrainianUI()}/>
            </div>
            {
              [...Array(count).keys()].map((idx) =>
                (
                  <div className="hero-slide section" key={idx}>
                    <Hero idx={idx} languageUA={isUkrainianUI()}/>
                  </div>
                )
              )
            }
            <div className="section hero-slide bg-color_flash-white">
              <Participants languageUA={isUkrainianUI()} ScrollTop={() => fullpageApi.moveTo(1)}/>
            </div>
          </div>
        );
      }}
    />
  )
}

export default Heroes;
