const DEFAULT_URL = "https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=ZNS7FXC4VLPYE&ssrt=1687245341450";
const DEFAULT_LABEL = "Donate";

const UrlButton = (props) => {
  const classes = ["btn", "btn-solid", props.className || []].join(" ");
  const label = props.label || DEFAULT_LABEL;

  const openUrl = () => {
    const url = props.url || DEFAULT_URL;

    window.open(url, '_blank').focus();
  }

  return (
    <button className={classes} type="btn" onClick={openUrl}>{label}</button>
  )
};

export default UrlButton;