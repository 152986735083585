import { useContext, useState } from "react";
import { HeroesContext } from "../context/HeroesContext";
import Lightbox from "react-spring-lightbox";
import Story from "../components/Story";
import UrlButton from "../components/UrlButton";

const PREFIX_PICTURE = '/pictures';

const Hero = ({ idx, languageUA }) => {
  const [isStoryOpen, setStoryState] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [isLightboxVisible, setLightboxVisibility] = useState(false);

  const { getHero } = useContext(HeroesContext);
  const hero = getHero(idx);
  if (hero === null) {
    return null;
  }

  const images = (() => hero.images.reduce((c, item) => {
    c.push({
      src: `${PREFIX_PICTURE}${item}`.replace(/\/+/g,"/"),
      loading: 'lazy',
      alt: `${hero.name} - ${hero.position}`,
    });

    return c;
  }, []))();

  const openLightbox = (e) => {
    e.preventDefault();
    setLightboxVisibility(true);
  };

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  const showStory = (e) => {
    e.preventDefault();
    setStoryState(true);
  };

  const hideStory = (e) => {
    e.preventDefault();
    setStoryState(false);
  }

  const onWheelAndTouchEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  return (
    <>
      <div className="container container-hero">
        <UrlButton className="btn-donate" />
        <div className="hero-slide__info">
          <p className="hero-slide__caption">{languageUA ? "LGBTIQ+ на захисті України" : "LGBTIQ+ in defense of Ukraine"}</p>
          <h3 className="hero-slide__title">{hero.name}</h3>
          <span className="hero-slide__subtitle">{hero.position}</span>
          {
            hero.preamble.map((text) => (<p>{text}</p>))
          }
          <a href="#full-story" className="hero-slide__show-more" onClick={showStory}>{languageUA ? "Показати повну історію" : "Show Full Story"}</a>
          <Story hero={hero} isOpen={isStoryOpen} onClose={hideStory} />
          <div className="hero-slide__image-preview">
            <img src={`/previews/${hero.preview}-picture-preview.jpg`} alt="HeroInfo" onClick={openLightbox} />
          </div>
          <a onClick={openLightbox} href="#view-all-images" className="hero-slide__show-more hero-slide__show-more--images">{languageUA ? "Дивитись всі зображення" : "View all images"}</a>
          <div onWheelCapture={onWheelAndTouchEvents} onTouchMove={onWheelAndTouchEvents}>
            <Lightbox
              isOpen={isLightboxVisible}
              onPrev={gotoPrevious}
              onNext={gotoNext}
              onClose={() => setLightboxVisibility(false)}
              images={images}
              currentIndex={currentImageIndex}
              className="hero-lightbox"
              renderHeader={() => (
                <header>
                  <div></div>
                  <div>
                    <button className="btn" type="button" onClick={() => setLightboxVisibility(false)}>
                      <img src="/icons/close.svg" alt="Close" />
                    </button>
                  </div>
                </header>
              )}
              renderPrevButton={() =>  {
                if (currentImageIndex) {
                  return (
                    <button className="btn btn-navigation btn-navigation_prev" type="button" onClick={() => gotoPrevious()}>
                      <img src="/icons/arrow.svg" alt="" />
                    </button>
                  )
                }
              }}
              renderNextButton={() => {
                if (currentImageIndex < images.length - 1) {
                  return (
                    <button className="btn btn-navigation btn-navigation_next" type="button" onClick={() => gotoNext()}>
                      <img src="/icons/arrow.svg" alt="" />
                    </button>
                  )
                }
              }}
            />
          </div>
        </div>
      </div>
      <img src={`/previews/${hero.preview}-picture.jpg`} alt="HeroInfo" className="hero-slide__picture" onClick={openLightbox}/>
    </>
  )
}

export default Hero;
