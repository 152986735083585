import { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LanguageSwitcher = () => {
  const { getNextLanguage, toggleLanguage } = useContext(LanguageContext);
  const onClick = (e) => {
    e.preventDefault();
    toggleLanguage();
  }

  return (
    <a href={`#${getNextLanguage()}-lang`} onClick={onClick} className="language-switcher">{getNextLanguage()}</a>
  );
}

export default LanguageSwitcher;